<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.acceptBook.title') }}
      </div>
      <section-loader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="desc">
          {{ $t('booking.modal.acceptTour.message') }}
        </div>
        <div class="date--time" v-if="modalData">
          {{ modalData.applicationDate }}
        </div>
        <hr class="separator" />
        <div class="d-flex justify-space-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-primary main--btn" @click="acceptTour">
            {{ buttonName }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');
import ModalMixin from '../mixins/index';

export default {
  name: 'initiate-confirmation-modal',
  mixins: [ModalMixin],
  components: { SectionLoader },
  data() {
    return {
      modalName: 'modal-accept-tour',
      modalData: null,
      actionType: 'TOUR_PROG',
      loading: false,
      buttonName: '',
    };
  },
  methods: {
    beforeOpen(event) {
      this._closed();
      this._applicationUuid = event.params.tourProgHeaderUuid;
      this.buttonName = event.params.buttonName;
      this._fetchApplicationData();
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async acceptTour() {
      this.loading = true;
      try {
        const { data } = await this.$http.post('api/v2/tour_schedule_prog/accept_from_rent', {
          tourScheduleProgressUuid: this._applicationUuid,
        });
        if (data) {
          this.$emit('actionDone');
          this._close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.tourModal.acceptedMsg'),
            'success',
          );
        }
      } catch (e) {
        // console.log('Error: ', e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
